import EN from './EN'
import TH from './TH'


export default {
  EN,
  TH,
  en:EN,
  th:TH,
}
